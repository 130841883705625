.Youtube {
  height:0;
  margin-bottom: 4rem;
  overflow:hidden;
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;

  & iframe, 
  & object, 
  & embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}