$color-primary--white: #fff;

$header-color: rgba($color-primary--white, 0.5);
$base-color: rgba($color-primary--white, 0.8);

$color--text: $base-color;
$color--header: $header-color;

$border: 1px solid rgba(255, 255, 255, 0.2);
$border-layout: 1px solid rgba(255, 255, 255, 0.5);

$font-size: 1.3rem;

$mobile-line-height: 5rem;