@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

.social-media {
	display: flex;
	// justify-content: space-between;
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		margin: 0 1rem 0 0;
		// padding: 1em 1em 1em 0;
		text-align: center;
			
		a {
			display: flex;
			align-items: center;
			color: $base-color;
			
			&:hover {
				color: #fff;
				text-decoration: none;
				
				&::before {
					color: #fff;
				}
			}

			span {
				display: none;
				font-size: 1.5rem;
				margin-left: 0.5rem;
				
				@include respond-to(medium) {
					display: inline-block;
				}
			}

			&::before {
				color: $base-color;
				display: block;
				font-family: "themicons";
				font-size: 4rem;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				@include respond-to(medium) {
					font-size: 4rem;
				}
			}
		}
	}

	&__facebook a::before {
		content: "\f103";
	}
	&__youtube a::before {
		content: "\f117";
	}
	&__instagram a::before {
		content: "\f109";
	}
}

body[data-mobile] {
	.social-media {
		li {
			a {
				line-height: $mobile-line-height;
				
				span {
					display: none;
				}
				
				&::before {
					font-size: $mobile-line-height;
					margin-right: 1rem;
				}
			}
		}
	}
}
