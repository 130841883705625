@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

.Events {
	&__item {
		display: flex;
		padding: 2rem;
		padding-right: 1rem;

		h3 {
			margin: 0;
		}
		
		&:not(:last-of-type) {
			border-bottom: $border;
		}

		&:first-of-type {
			background-color: rgba($color--text, 0.1);
			border: none;

			h3 {
				color: $color-primary--white;
			}
		}
		

		&--day {
			display: flex;
			flex-flow: column;
			font-size: 1.6rem;
			text-align: left;
			width: 80px;

			b {
				font-size: 1.6rem;
				font-weight: 400;
				margin-bottom: 0.5rem;
				color: $color-primary--white;
			}
			&ofweek {
				text-transform: capitalize;
				color: $color--header;
			}
		}

		&--place {
			font-size: 1.7rem;
		}

		&--info {
			flex: 1;
			padding-left: 1rem;
		}

		&--fb-event {
			font-size: 1.3rem;
			margin-top: 1rem;
		}
		&--tickets {
			font-size: 1.3rem;
			margin-top: 1rem;
		}
	}
}

@include respond-to(small) {
	.Events__item {
		h3 {
			font-size: 1.7rem;
		}
	}
}

@include respond-to(medium) {
	.Events__item {
		h3 {
			font-size: 2rem;
		}
		&--day b {
			margin-bottom: 1rem;
		}
	}
}