@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-title {
  color: $color--header;
  font-weight: 300;
  letter-spacing: .5rem;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  
  &::after {
    // content: 'WELCOME TO THE JUNGLE';
    font-size: 1.5rem;
    display: block;
    letter-spacing:  0.1em;
    color: $base-color;
  }

  // @include respond-to(medium) {
  //   font-size: 10rem;

  //   &::after {
  //     font-size: 2.3rem;
  //   }
  // }
}