@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

.App:not([data-section]) {
  header {
    height: 100vh;
    position: relative;

    .site-title {
      cursor: pointer;

      &:not([data-scrolled])::after {
        background: url('../../images/arrow-circle-down.svg') no-repeat center;
        background-size: 7.5rem;
        background-color: $color--header;
        border-radius: 50%;
        content:" ";
        display: inline-block;
        opacity: 0.7;
        position: absolute;
        bottom: 5rem;
        left: calc(50% - 3.5rem);
        height: 6.5rem;
        width: 6.5rem;
      }

      &:hover::after{
        background-color: #fff;
        opacity: 1;
      }


      @include respond-to(small) {
        font-size: 10rem;
      }
      
      @include respond-to(medium) {
        font-size: 15rem;
      }
    }
  }

  .page-content {
    // height: 100%;
    // display: flex;
    // flex-flow: column;
  }
}


body[data-mobile] .App:not([data-section]) {
  height: auto;

  .page-content {
    height: auto;
    padding: 1rem 0 2rem;
  }
}

.Homepage {
  h2 {
    border-bottom: $border-layout;
    padding-bottom: .5rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      &::after, &::before {
        color: #fff;
        display: block;
        font-family: "themicons";
        font-size: 3rem;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  &__Youtube {
    h2 {
      a::before {
        content: "\f117";
        margin-right: 1rem;
      }
    }
  }

  &__InstagramFeed {
    h2 {
      a::before {
        content: "\F109";
        margin-right: 1rem;
      }
    }
  }

}

@include respond-to(medium) {
  .Homepage {
    display: flex;
    padding: 0 4rem;

    .column {
      &:first-child {
        padding-right: 2.5rem;
        flex: 3;
      }
    }

    &__Events,
    &__Youtube {
      h2 {
        text-align: left;
        
        a {
          justify-content: flex-start;
        }
      }
    }
  
    &__InstagramFeed {
      flex: 1;
      
      h2 {
        a {
          justify-content: flex-end;
          &::before {
            content: '';
          }
          &::after {
            content: "\F109";
            margin-left: 1rem;
          }
        }
      }
    }
  }
}