.Loader {
  bottom: 0;
  background: rgba(0,0,0, .9) url('./Loader.svg') no-repeat center calc(50% - 5rem);
  background-size: 10rem;
  color: rgba(255, 255, 255, 0.7);
  left: 0;
  position: absolute;
  right: 0;
  top:0;
  z-index: 1000;

  // &::after {
  //   content: 'Načítám obsah';
  //   position: absolute;
  //   font-size: 1.7rem;
  //   top: calc(50%);
  //   left: calc(50% - 5.9rem);
  // }
}