@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

@font-face {
  font-family: "themicons";
  src: url('fonts/themicons.woff2') format("truetype");
}

*, 
*::before, 
*::after {
  box-sizing: border-box;
  // transition: all .2s ease-in-out;
}

html {
  font-size: 62.5%;
}
html, 
body, 
.app-root {
	height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: $font-size;
  background: center top fixed no-repeat #000;
  background-image: linear-gradient(
      rgba(0, 0, 0, .8), 
      rgba(0, 0, 0, .8)
    ),
    url('images/desktop_01.jpg');
  background-size: cover;
  color: $color--text;
  line-height: 1.15;

  // &[cover-1] {    }
  // &[cover-2] {
  //   background-image: linear-gradient(
  //     rgba(0, 0, 0, .8), 
  //     rgba(0, 0, 0, .8)
  //   ),
  //   url('images/desktop_02.jpg');
  // }
}

a {
  color: $base-color;
  text-decoration: none;
  
  &[disabled] {
    cursor: auto;
    pointer-events: none;
    color: #aaa;
  }
}

a:hover, a:focus {
	color: #fff;
}

.grid {
  display: flex;

  &[data-layout='space-between'] {
    justify-content: space-between;
  }

  // &--cell {}
}

.app-root {
  max-width: 1200px;
  margin: 0 auto;
}

.App {
  margin: 0 2rem;
  display: flex;
  flex-flow: column;

  .page-content {
    padding: 2rem 0;
    position: relative;
  }

  footer {
    align-items: center;
    border-top: $border-layout;
    font-size: 1.5rem;
    padding: 1.5rem 0;

    a.dropbox {
      align-items: center;
      display: flex;

      &::before {
        background: url('images/dropbox.svg') no-repeat center;
        background-size: contain;
        content: ' ';
        display: inline-block;
        filter: grayscale(75%);
        height: 2.5rem;
        margin-right: 1rem;
        width: 2.5rem;
      }
    }

    .contacts {
      a {
        color: #fff;
        line-height: 2.5rem;
      }
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.65rem;
    letter-spacing: 0.05rem;
  }

  h1, h2, h3, h4, h5 {
    text-transform: uppercase;
  }

  h2 {
    text-align: center;
    color: $color-primary--white;
    font-size: 2.6rem;
    font-weight: 400;
    margin: 1rem 0 2rem;
    padding:0;
    letter-spacing: 0.1em;

    @include respond-to(medium) {
      font-size: 2.5rem;
      margin: 2rem 0 5rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 1.25em 0;
    letter-spacing: 0.05em;
  }
}

@keyframes blur {
	0% {   filter: blur(0px); }
	25% {  filter: blur(1px); }
	50% {  filter: blur(2px); }
	75% {  filter: blur(1px); }
	100% { filter: blur(0px); }
}

body[data-mobile='true'] {
  background-size: 145%;

  background-image: linear-gradient(
      rgba(0, 0, 0, .8), 
      rgba(0, 0, 0, .8)
    ),
    url('images/mobile_02.jpg');

  .App {
    margin: 0rem;
  }
  
  .site-title {
    font-size: 6rem;

    &::after {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }


  h2 {
    margin: 1.5rem 0 2rem;
  }

  footer {
    a {
      line-height: $mobile-line-height;
    }

    .grid--cell:last-of-type {
      font-size: 1.5rem;
    }

    @media (orientation: portrait) {
      margin-top: 0;
      flex-flow: column;
    }
  }
}

@include respond-to(small) {
  body {
    // background-size: contain;
  }
}