@import 'styles/_constants.scss';
@import 'styles/_breakpoints.scss';

.InstagramFeed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  figure {
    margin: 0 0 4rem 0;

    img {
    }
  }
}